import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Telecom Design Agency`,
    text: "UI UX Design for Telecom",
    content: "Our Telecom Design Agency crafts transformative digital experiences by merging user-centric design with industry expertise. Leveraging the latest telecom UX, we develop intuitive interfaces and workflows to boost your operational efficiency.",
    list: [
      'Proven Telecom Experience',
      'Innovative Network Solutions',
      'Customized Telecom Services'
    ]
  };
  const ourData = {
    title: `Clients Thoughts On Our <span class='h1-span'>Telecom Design Services</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Quantify Success With Our <span class='h1-span'>Telecom Design Company</span>",
    content: [
      {
        title: "7+ Years of Telecom Experience",
        text: "With over 7+ years of experience, we specialize in telecom website design and understand the unique needs and challenges of the telecommunications sector, ensuring our solutions are both innovative and effective.",
      },
      {
        title: "10+ Team of Experts",
        text: "Our telecom web design company has a dynamic team of 10+ experts, each possessing a deep understanding of UI UX design for telecom and delivering cutting-edge solutions that exceed client expectations.",
      },
      {
        title: "80+ Telecom UX Projects",
        text: "From mobile apps to websites, we have demonstrated our ability to create intuitive and user-friendly interfaces with our telecom design services that enhance the usability and overall customer experience.",
      },
      {
        title: "98% Client Satisfaction Rate",
        text: "Our telecom design agency prioritizes transparency and responsiveness throughout the process and builds a long-lasting partnership, ensuring our clients are involved and informed at every step.",
      },
    ],
  };
  const commonContent1 = {
    title: "Amplify Business With Our Telecom Design Agency",
    content: [
      {
        title: "Seamless Web Integration",
        text: "Our team of experts seamlessly integrates telecom solutions into a cohesive, user-friendly web presence, enhancing your offerings and customer experience.",
      },
      {
        title: "Increased Lead Generation",
        text: "By leveraging telecom web design strategies, we attract more qualified leads, driving higher website traffic, engagement, and conversions, fueling your sales pipeline.",
      },
      {
        title: "Competitive Telecom Advantage",
        text: "Our telecom design agency creates innovative and user-friendly web solutions that help you stand out from the competition in the telecom landscape.",
      },
      {
        title: "Streamlined Operations",
        text: "We incorporate intuitive navigation and optimize your online infrastructure to enhance telecom workflows and streamline operational efficiency.",
      },
      {
        title: "Enhanced Brand Visibility",
        text: "Our strategic design expertise elevates your brand's online visibility and credibility, building trust and awareness among your target audience.",
      },
      {
        title: "Measurable Performance",
        text: "Our telecom design solutions are backed by data-driven insights, providing you with real-time performance metrics to help you make informed decisions.",
      },
    ],
  };
  const Questions = [
    "What services does your telecom design agency offer? ",
    "How does your agency approach telecom design projects?",
    "What sets your telecom design agency apart from others?",
    "How long does it take to complete a telecom design project?",
    'How can I get started with your telecom design agency?'
  ];
  const faqData = [
    {
        para: [
            `We specialize in various services tailored to the telecom industry, including telecom website design, <a title='UI UX design' target='_blank' href='/ui-ux-designing/'>UI UX design</a> for telecom, mobile app development, <a title='branding' target='_blank' href='/branding/'>branding</a>, and <a target='_blank' href='/services/' title='services'>many more</a>!`
        ]
    },
    {
      para: [
        `Our approach to design projects is collaborative and research-driven. We begin by understanding your goals and target audience and conduct thorough research to inform our design decisions. From there, we iterate through design concepts, incorporating feedback at every stage to ensure the final product exceeds your expectations.`
      ],
    },
    {
      para: [
        `Our agency stands out due to our extensive experience in the telecom industry, our dedicated team of experts, and our commitment to delivering innovative and user-friendly solutions. We prioritize client satisfaction and strive to build long-lasting partnerships based on trust and reliability.`
      ]
    },
    {
      para: [
        `The timeline to complete a project can vary depending on the scope and requirements. However, we work efficiently to deliver results in a timely manner without compromising on quality. We provide clear timelines and milestones at the project's outset to ensure transparency and accountability.`
      ],
    },
    {
        para: [
          `Getting started with us is easy. Simply <a href='/contact-us/' title='contact us' target='_blank'>contact us</a> through our website, and one of our team members will contact you to discuss your project requirements and how we can help bring your vision to life. We look forward to collaborating with you!`
        ],
      }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "What services does your telecom design agency offer?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We specialize in various services tailored to the telecom industry, including telecom website design, UI UX design for telecom, mobile app development, branding, and many more!"
            }
          },{
            "@type": "Question",
            "name": "How does your agency approach telecom design projects?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our approach to design projects is collaborative and research-driven. We begin by understanding your goals and target audience and conduct thorough research to inform our design decisions. From there, we iterate through design concepts, incorporating feedback at every stage to ensure the final product exceeds your expectations."
            }
          },{
            "@type": "Question",
            "name": "What sets your telecom design agency apart from others?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our agency stands out due to our extensive experience in the telecom industry, our dedicated team of experts, and our commitment to delivering innovative and user-friendly solutions. We prioritize client satisfaction and strive to build long-lasting partnerships based on trust and reliability."
            }
          },{
            "@type": "Question",
            "name": "How long does it take to complete a telecom design project?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The timeline to complete a project can vary depending on the scope and requirements. However, we work efficiently to deliver results in a timely manner without compromising on quality. We provide clear timelines and milestones at the project's outset to ensure transparency and accountability."
            }
          },{
            "@type": "Question",
            "name": "How can I get started with your telecom design agency?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Getting started with us is easy. Simply contact us through our website, and one of our team members will contact you to discuss your project requirements and how we can help bring your vision to life. We look forward to collaborating with you!"
            }
          }]
        }                                                      
        `}
      </script>
    </Helmet>
    <Layout hire="Telecom Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Telecom Design Agency | Telecom Website Design"
    description="Our telecom design agency offers specialized telecom UX design, driving superior user experiences and innovation in the telecom industry. Contact us now!"
  />
);
